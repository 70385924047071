#credits {
    display: flex;
    align-items: center;
    padding-top: 100px;
  }
  
  .ending-credits {
    font-family: "NTR", sans-serif;
    color: var(--slate);
    text-align: center;
    width: 100%;
    font-size: 16px;
    padding-bottom: 40px;
  }